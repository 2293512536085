<template>
  <div id="manage" class="h-100 w-100">
    <div class="wrapper w-100 overflow-auto">
      <Sidebar> </Sidebar>
      <div class="main-panel">
        <Header />
        <div class="content">
          <div class="container-fluid">
            <div class="card card-4">
              <div class="card-body">
                <h2>ユーザーの更新</h2>
                <p style="color: red; font-size: 15px">
                  {{ message == "" ? "&nbsp;" : message }}
                </p>
                <div class="row row-space">
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">ユーザーＩＤ</label>
                      <input
                        class="input--style-4"
                        type="text"
                        ref="username"
                        name="username"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">氏名</label>
                      <input
                        class="input--style-4"
                        type="text"
                        ref="name"
                        name="last_name"
                      />
                    </div>
                  </div>
                </div>
                <div class="row row-space">
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">パスワード</label>
                      <div class="input-group-icon" style="width: 100%">
                        <input
                          class="input--style-4 js-datepicker"
                          type="password"
                          name="password"
                          ref="password"
                        />
                        <i
                          class="zmdi zmdi-calendar-note input-icon js-btn-calendar"
                        ></i>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">パスワードを認証する</label>
                      <input
                        class="input--style-4"
                        type="password"
                        name="repassword"
                        ref="repassword"
                      />
                    </div>
                  </div>
                </div>
                <div class="row row-space">
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">室名</label>
                      <input
                        class="input--style-4"
                        type="text"
                        ref="operator"
                        name="operator"
                        :disabled="disabled == 1"
                      />
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">アカウントのステータス</label>
                      <div class="" style="width: 160px">
                        <label class="radio-container m-r-45"
                          >アクティブ
                          <input
                            type="radio"
                            checked="checked"
                            v-model="isActive"
                            name="active"
                            autocomplete="off"
                            v-bind:value="true"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="radio-container"
                          >非アクティブ
                          <input
                            type="radio"
                            v-model="isActive"
                            name="active"
                            v-bind:value="false"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-6">
                    <div class="input-group">
                      <label class="label">ルームマスター</label>
                      <div class="" style="width: 160px">
                        <label class="radio-container m-r-45"
                          >マスター
                          <input
                            type="radio"
                            checked="checked"
                            v-model="isMaster"
                            name="actived"
                            autocomplete="off"
                            v-bind:value="true"
                          />
                          <span class="checkmark"></span>
                        </label>
                        <label class="radio-container"
                          >メンバー
                          <input
                            type="radio"
                            v-model="isMaster"
                            name="actived"
                            v-bind:value="false"
                          />
                          <span class="checkmark"></span>
                        </label>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="input-group">
                  <label class="label">ポジション</label>
                  <div style="width: 500px">
                    <select
                      name="role"
                      class="custom-select"
                      ref="roleid"
                      @change="onChange($event)"
                    >
                      <option
                        value="1"
                        :selected="userdetail.roleId == 1 ? true : false"
                      >
                        管理者
                      </option>
                      <option
                        value="2"
                        :selected="userdetail.roleId == 2 ? true : false"
                      >
                        ユーザ
                      </option>
                    </select>
                    <div class="select-dropdown"></div>
                  </div>
                </div>
                <div class="p-t-15">
                  <button
                    class="btn btn--radius-2 btn-warning w-50 btn-create"
                    v-on:click="updateSubmit()"
                  >
                    更新
                  </button>
                  <button
                    class="btn btn--radius-2 btn-info w-30 btn-create"
                    v-on:click="back()"
                  >
                    戻る
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    </div>
  </div>
</template>

<script >
import Sidebar from "../dashboard/Sidebar.vue";
import Header from "../dashboard/Header.vue";
import Footer from "../dashboard/Footer.vue";
import axios from "axios";

axios.defaults.headers.post["Content-Type"] = "application/json";
export default {
  name: "dashboard",
  components: {
    Header,
    Sidebar,
    Footer,
  },

  data() {
    return {
      user: [],
      data: [],
      isActive: true,
      disabled: 0,
      message: "",
      token: "",
      userdetail: [],
      selectedOption1: true,
      selectedOption2: false,
      OPENVIDU_SERVER_URL: process.env.VUE_APP_OPENVIDU_SERVER_URL,
      isMaster: false,
    };
  },
  async mounted() {
    $("body").css({
      background:
        "linear-gradient(45deg, rgba(101, 31, 87, 1) 0%,rgba(225, 113, 87, 1) 48%,rgba(249, 248, 113, 1) 100%)",
    });
    $('.content>.container-fluid').css('margin-top',50);
    if (!this.$cookies.isKey("token")) {
      this.$router.push("/login");
    } else {
      this.token = this.$cookies.get("token");
      this.checkAuthen(this.token).then((data) => {
        if (data != null) {
          this.user = data;
          if (this.user.roleId != 1) {
            this.logoutSesion();
            this.$router.push({
              path: "/login",
              query: { message: "ユーザーＩＤは管理者ではありません" },
            });
            // this.$cookies.remove("token");
            // this.$cookies.remove("usertoken");
          } else {
            if (!this.user.active) {
              this.logoutSesion();
              this.$router.push({
                path: "/login",
                query: { message: "ユーザーＩＤがアクティブではありません" },
              });
                // this.$cookies.remove("token");
                // this.$cookies.remove("usertoken");
            } else {
              if (this.$route.query.userid != undefined) {
                if (this.$route.query.userid != "") {
                  this.getUser(this.$route.query.userid, this.token).then((data) => {
                      if (data.length != 0) {
                        if (this.user.id == data.id) {
                          $('input[name="username"]').prop("disabled", true);
                          $('input[name="active"]').prop( "disabled", true );
                        }
                        if (data.username == "admin") {
                          $('input[name="active"]').prop( "disabled", true );
                          $('select[name="role"]').prop("disabled", true);
                          $(
                            'input[type="radio"],input[type="text"], input[type="checkbox"], select'
                          )
                            .prop("disabled", true)
                            .each(function () {
                              if ($(this).is(":disabled")) {
                                $(this).css('background-color', '#dddddd');
                              }
                            });
                        }
                        this.userdetail = data;
                        this.$refs.username.value = this.userdetail.username;
                        this.$refs.password.value = "password";
                        this.$refs.repassword.value = "password";
                        this.$refs.name.value = this.userdetail.name;
                        this.$refs.operator.value = this.userdetail.operator;
                        this.isMaster = this.userdetail.checkMasterRoom;
                        this.isActive = this.userdetail.active;
                        if (this.userdetail.roleId == 1) {
                          this.disabled = 1;
                        }
                      }
                    }
                  );
                }
              }
            }
          }
        } else {
          this.logoutSesion();
          this.$router.push("/login");
          // this.$cookies.remove("token");
          // this.$cookies.remove("usertoken");
        }
      });
    }
  },
  methods: {
    logout() { 
      this.logoutSesion();
      // this.$cookies.remove("token");
      // this.$cookies.remove("usertoken");
      this.$router.push("/login");
    },
    logoutSesion(){
      var token = this.$cookies.get("token");
      // if(token!= null || token != ''){
      //   this.delAuthen(token);
      // }
      this.$cookies.remove("token");
      this.$cookies.remove("usertoken");
    },
    delAuthen(tokenid) {
      axios
        .post(`${this.OPENVIDU_SERVER_URL}/vti/delAuthen`, {
          token: tokenid,
        })
        .then((response) => {
          if (response.status === 200) {
            if (response.data == "success") {
              this.flg = true;
            }
          }
        })
        .catch((error) => console.log(error));
    },
    back() {
      this.$router.push("/administrator");
    },
    updateSubmit() {
      this.$confirm("データを更新しますか？").then(() => {
        var usernametemp = this.$refs.username.value;
        var passwordtemp = this.$refs.password.value;
        var repasswordtemp = this.$refs.repassword.value;
        var nametemp = this.$refs.name.value;
        var operatortemp = this.$refs.operator.value;
        var activetemp = this.isActive;
        var roleidtemp = this.$refs.roleid.value;
        var masterCheck = this.isMaster;
        if (usernametemp != "") {
          //regex
          if (/^\w+$/.test(usernametemp)) {
            if (passwordtemp != "") {
              if (/^([a-zA-Z0-9]{6,})$/.test(passwordtemp)) {
                if (repasswordtemp != "") {
                  if (passwordtemp === repasswordtemp) {
                    if (nametemp != "") {
                      if (roleidtemp == 1) {
                        if (passwordtemp == "password") {
                          this.data = {
                            id: this.userdetail.id,
                            username: usernametemp,
                            password: "",
                            name: nametemp,
                            operator: "",
                            active: activetemp,
                            roleid: roleidtemp,
                            master: masterCheck,
                          };
                        } else {
                          this.data = {
                            id: this.userdetail.id,
                            username: usernametemp,
                            password: passwordtemp,
                            name: nametemp,
                            operator: "",
                            active: activetemp,
                            roleid: roleidtemp,
                            master: masterCheck,
                          };
                        }
                      } else if (roleidtemp == 2) {
                        if (roleidtemp == 2 && operatortemp != "") {
                          if (/^\w+$/.test(operatortemp)) {
                            if (passwordtemp == "password") {
                              this.data = {
                                id: this.userdetail.id,
                                username: usernametemp,
                                password: "",
                                name: nametemp,
                                operator: operatortemp,
                                active: activetemp,
                                roleid: roleidtemp,
                                master: masterCheck,
                              };
                            } else {
                              this.data = {
                                id: this.userdetail.id,
                                username: usernametemp,
                                password: passwordtemp,
                                name: nametemp,
                                operator: operatortemp,
                                active: activetemp,
                                roleid: roleidtemp,
                                master: masterCheck,
                              };
                            }
                          } else {
                            this.message = "半角英数字のみご入力ください！";
                          }
                        } else {
                          this.message = "室名を入力してください";
                          // operator empty
                        }
                      } else {
                        this.message = "Role is not choose";
                      }
                    } else {
                      this.message = "氏名を入力してください";
                    }
                  } else {
                    // password and repassword not same
                    this.message =
                      "パスワードが一致しませんでした。もう一度お試しください。";
                  }
                } else {
                  //repassword empty
                  this.message = "パスワードを認証するを入力してください";
                }
              } else {
                this.message = "半角英数字と6文字以上をご入力ください！";
              }
            } else {
              //password empty
              this.message = "パスワードを入力してください";
            }
          } else {
            this.message = "半角英数字のみご入力ください！";
          }
        } else {
          // user empty
          this.message = "ユーザーＩＤを入力してください";
        }

        if (this.data.length != 0) {
          this.updateUser(this.token, this.data, this.userdetail);
        }
      });
    },
    onChange(event) {
      if (event.target.value == 1) {
        this.disabled = 1;
        this.$refs.operator.value = "";
      } else {
        this.disabled = 0;
      }
    },
    getUser(id, tokenclient) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/vti/getUserById`,
            JSON.stringify({
              userid: id,
              token: tokenclient,
            })
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                resolve(response.data);
              } else {
                reject(error.response);
              }
            }
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },
    checkAuthen(tokenclient) {
      return new Promise((resolve, reject) => {
        axios
          .post(`${this.OPENVIDU_SERVER_URL}/vti/authentication`, {
            token: tokenclient,
          })
          .then((response) => {
            if (response.status === 200) {
              if (response.data != null) {
                resolve(response.data);
              } else {
                reject(error.response);
              }
            }
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },
    updateUser(tokenclient, data, user) {
      return new Promise((resolve, reject) => {
        axios
          .post(
            `${this.OPENVIDU_SERVER_URL}/vti/updateUser`,
            JSON.stringify({
              data,
              token: tokenclient,
              user,
            }),
            {}
          )
          .then((response) => {
            if (response.status === 200) {
              if (response.data != "fail") {
                if (response.data != "Username Existed") {
                  if(response.data != "Master Existed"){
                    if(response.data != "Master Existed in the new room"){
                      if (response.data != "Operator Existed") {
                        this.$router.push("/administrator");
                      } else {
                        this.message =
                          "この室名は既に使用されています。別の室名を選択してください。";
                      }
                    }else{
                      this.message =
                        "新しい部屋にはすでにマスターアカウントがあります";
                    }
                  }else{
                    this.message =
                        "部屋にはすでにマスターとしてのアカウントがあります";
                  }
                  
                } else {
                  this.message =
                    "このユーザーＩＤは既に使用されています。別のユーザーＩＤを選択してください。";
                }
              } else {
                reject(error.response);
              }
            }
          })
          .catch((error) => {
            reject(error.response);
            this.$router.go(0);
          });
      });
    },
  },
};
</script>
<style scoped>
@import "../assets/css/material-dashboard.css";
@import "../assets/css/css.css";
/* @import "../assets/css/font-awesome.min.css"; */
@import "../assets/demo/demo.css";
@import "../assets/css/mainAdd.css";
html {
  height: 100%;
  overflow: hidden;
}

body {
  border: 0;
  margin: 0;
  padding: 0;
  height: 100%;
  /* background-color: #fff; */
  /* background: linear-gradient(
    45deg,
    rgba(101, 31, 87, 1) 0%,
    rgba(225, 113, 87, 1) 48%,
    rgba(249, 248, 113, 1) 100%
  ); */
}
/* #manage{
  background: linear-gradient(
    45deg,
    rgba(101, 31, 87, 1) 0%,
    rgba(225, 113, 87, 1) 48%,
    rgba(249, 248, 113, 1) 100%
  ) !important;
} */
.input-group label {
  font-weight: 600;
}
.logo {
  align-self: center;
  color: #fff;
  font-weight: bold;
  font-family: "Lato";
}
input[name="username"]:disabled {
  background: #dddddd;
}
input[name="operator"]:disabled {
  background: #dddddd;
}
.main-nav {
  display: flex;
  justify-content: space-between;
  padding: 0.5rem 0.8rem;
}

ul.sidebar-panel-nav {
  list-style-type: none;
}

ul.sidebar-panel-nav > li > a {
  color: #fff;
  text-decoration: none;
  font-size: 1.5rem;
  display: block;
  padding-bottom: 0.5em;
}
.card {
  max-width: 680px;
  margin: auto;
}
.custom-select {
  position: relative;
  font-family: Arial;
  height: 40px;
  font-size: 15px;
}

.custom-select select {
  display: none; /*hide original SELECT element: */
}

.select-selected {
  background-color: DodgerBlue;
}

/* Style the arrow inside the select element: */
.select-selected:after {
  position: absolute;
  content: "";
  top: 14px;
  right: 10px;
  width: 0;
  height: 0;
  border: 6px solid transparent;
  border-color: #fff transparent transparent transparent;
}

/* Point the arrow upwards when the select box is open (active): */
.select-selected.select-arrow-active:after {
  border-color: transparent transparent #fff transparent;
  top: 7px;
}

/* style the items (options), including the selected item: */
.select-items div,
.select-selected {
  color: #ffffff;
  padding: 8px 16px;
  border: 1px solid transparent;
  border-color: transparent transparent rgba(0, 0, 0, 0.1) transparent;
  cursor: pointer;
}

/* Style items (options): */
.select-items {
  position: absolute;
  background-color: DodgerBlue;
  top: 100%;
  left: 0;
  right: 0;
  z-index: 99;
}

/* Hide the items when the select box is closed: */
.select-hide {
  display: none;
}

.select-items div:hover,
.same-as-selected {
  background-color: rgba(0, 0, 0, 0.1);
}
</style>